<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init" class="cur-a">班级管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="班级编码" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级编码:</span>
                <el-input
                  v-model="projectCode"
                  type="text"
                  size="small"
                  placeholder="请输入班级编码 "
                  onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                  clearable
                />
              </div>
              <div title="班级名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="projectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div class="df">
                <transition name="el-zoom-in-center">
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="
                      () => {
                        getData();
                      }
                    "
                    >查询</el-button
                  >
                </transition>
              </div>
            </div>
            <div class="btnBox">
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addTestPaper"
                >创建班级</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              v-if="showtable"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200px"
                fixed="left"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="250px"
                fixed="left"
              />
              <template v-if="tableHaed.length">
                <el-table-column
                  v-for="(el, index) in tableHaed"
                  :key="index"
                  :label="el.label"
                  :align="el.textAlign"
                  show-overflow-tooltip
                  :min-width="el.width"
                >
                  <template slot-scope="scope">{{
                    getValue(el, scope.row)
                  }}</template>
                </el-table-column>
              </template>
              <el-table-column
                  label="备注"
                  align="left"
                  prop="auditReason"
                  min-width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.auditReason ? scope.row.auditReason : '--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                width="300px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="seeDetails(scope.row)"
                      >详情</el-button
                    >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding: 0 5px"
                        @click="jumpclassList(scope.row)"
                    >课程列表</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpStudentList(scope.row)"
                      >学员管理</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="
                        keeprecord(
                          scope.row.projectId,
                          scope.row.projectSource,
                          scope.row.courseCount,
                          scope.row
                        )
                      "
                      :disabled="
                        scope.row.auditState == '20' ||
                        scope.row.auditState == '25' ||
                        scope.row.auditState == '30'
                      "
                      >开班申请</el-button
                    >

                    <el-button
                      type="text"
                      style="padding: 0 5px"
                      size="mini"
                      @click="showDocsDialog(scope.row)"
                      :disabled="scope.row.projectStudyTimeState == '10'"
                      >培训档案</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form
              ref="docsFormnew"
              :model="docsFormnew"
              class="docsDialog"
              v-show="danganList.length"
            >
              <el-form-item v-for="(item, index) in danganList" :key="index">
                <el-checkbox v-model="docsFormnew[item.dictCode]">{{
                  item.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      width="800px"
      title="提示"
      :visible.sync="paraIsPopDialog"
      top="2%"
    >
      <p style="font-size: 1rem; padding: 15px 1rem">
        提示：根据监管要求每学时弹题<span
          style="color: #f56c6c; font-size: 1.25rem"
          >{{ popNum }}</span
        >次，课件弹题配置题目数量少于课件学时*<span
          style="color: #f56c6c; font-size: 1.25rem"
          >{{ popNum }}</span
        >道(四舍五入)，不合格课件列表如下:
      </p>
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="retData"
          height="500px"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="课件名称"
            align="center"
            show-overflow-tooltip
            prop="courseName"
            min-width="150px"
          />
          <el-table-column
            label="应配弹题题目(道)"
            align="center"
            show-overflow-tooltip
            prop="minPopNum"
            min-width="150px"
          />
          <el-table-column
            label="实际配弹题题目(道)"
            align="center"
            show-overflow-tooltip
            prop="currPopNum"
            min-width="150px"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <!-- 山西 -->
    <el-dialog width="800px" title="提示" :visible.sync="SxDialog" top="2%">
      <p style="font-size: 1rem; padding: 15px 1rem">
        提示：根据监管要求课后习题必须等于<span
          style="color: #f56c6c; font-size: 1.25rem"
          >{{ homeworkLimit }}</span
        >道，总分必须为<span style="color: #f56c6c; font-size: 1.25rem">{{
          100
        }}</span>分；阶段性测试不少于<span style="color: #f56c6c; font-size: 1.25rem">{{
          unitLimit
        }}</span
        >道，及格分数不能低于总分的<span
          style="color: #f56c6c; font-size: 1.25rem"
          >{{ qualifiedLimit * 100 }}%</span
        >，试卷内题目分值<span style="color: #f56c6c; font-size: 1.25rem"
          >相同</span
        >；课件时长必须大于<span style="color: #f56c6c; font-size: 1.25rem">{{
          45
        }}</span>分钟小于<span style="color: #f56c6c; font-size: 1.25rem">{{
          60
        }}</span>分钟，不合格列表如下:
      </p>
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="retData"
          height="500px"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="名称"
            align="center"
            show-overflow-tooltip
            prop="paperName"
            min-width="150px"
          />
          <el-table-column
            label="类型"
            align="center"
            show-overflow-tooltip
            prop="errorType"
            min-width="150px"
          >
          <template slot-scope="scope">{{scope.row.errorType == '20' ? '课件':'试卷'}}  </template>
          </el-table-column>
          <el-table-column
            label="不合格t原因"
            align="center"
            show-overflow-tooltip
            prop="errorReason"
            min-width="150px"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <p style="padding-bottom: 1rem">请核对班级行政区划和期次</p>
      <div
        style="display: flex; align-items: center; text-align: right"
        class="el-dialogs"
      >
        <span class="itemLabel" style="min-width: 6rem">班级行政区划:</span>
        <el-cascader
          clearable
          filterable
          v-model="areaIdCompId"
          :options="areatreeList"
          :props="propsarea"
          size="small"
        ></el-cascader>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          margin-top: 1rem;
          text-align: right;
        "
        class="el-dialogs"
      >
        <span class="itemLabel" style="min-width: 6rem">期次:</span>
        <el-input-number
          v-model="Periodnum"
          @change="handleChange"
          :min="1"
          size="small"
        >
        </el-input-number>
        <span>期</span>
      </div>
      <p style="color: #dd1d35">
        注意:请准确填写期次,数据会显示补贴标准申请材料中
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="areaIdSure" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListManage",
  components: {
    Empty,
    PageNum,
    // tree,
  },
  mixins: [List],
  data() {
    return {
      danganList: [], // 档案列表
      docsFormnew: {},
      projectIdSel: "", //选择的班级ID
      showtable: false,
      innerVisible: false,
      paraIsPopDialog: false, //弹题错误数据
      dialogVisible: false,
      Periodnum: 1,
      SxDialog: false, //山西
      areaIdCompId: "",
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      projectName: "",
      projectCode: "",
      ruleForm: {

      },
      docsDialogVisible: false,
      agreeState: false, //弹框是否同意
      selectedItem: {},
      params: {},

      // 自定义表头
      tableHeadDialog: false, //
      treeData: [
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "开始时间", //表头名称
          propName: "startDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "结束时间", //表头名称
          propName: "endDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "报名截止", //表头名称
          propName: "signEndDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "班级状态", //表头名称
          propName: "auditState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
      ],
      tableHaed: [],
      treeDataCopy: [],
      Spare: "",
      retData: [],
      popNum: "",
      area: {},
      homeworkLimit: "",
      qualifiedLimit: "",
      unitLimit: "",
      previewLoding1: false,
      ImgSrc: "",
    };
  },
  created() {
    this.getTableHeight();
    this.getareatree();
    // this.getclassstatusList();
    this.getTableHead();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "auditState") {
          if (
            row.auditState == "10" ||
            row.auditState == "20" ||
            row.auditState == "40"
          ) {
            return this.$setDictionary("AUDITSTATE", row.auditState) == "未提审"
              ? "未申请"
              : this.$setDictionary("AUDITSTATE", row.auditState) ==
                "审核未通过"
              ? "未通过"
              : this.$setDictionary("AUDITSTATE", row.auditState);
          } else if (
            row.auditState == "30" &&
            row.projectStudyTimeState != "10"
          ) {
            return this.$setDictionary(
              "PROJECTSTATE",
              row.projectStudyTimeState
            ) == "审核未通过"
              ? "未通过"
              : this.$setDictionary("PROJECTSTATE", row.projectStudyTimeState);
          } else if (row.auditState == "30") {
            return this.$setDictionary("AUDITSTATE", row.auditState);
          }
          if (row.auditState == "25") {
            return "运营审核通过";
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "momentDate") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "classListCtr",
      }).then((res) => {
        // ;
        this.getTableHead();
      });
    },
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "classListCtr",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            this.handle();
            // this.tableHaed = [...this.treeData];
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectName) {
        params.projectName = this.projectName.trim();
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode.trim();
      }

      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch(
        {
          url: "/biz/project/listPageTrain",
          params,
          pageNum,
        },
        true
      );
      // this.getTotal(params);
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //删除
    deleteproject(projectId) {
      this.doDel({
        url: "/biz/project/delete",
        msg: "你确定要删除该班级吗？",
        ps: {
          type: "post",
          data: { projectId },
        },
      });
    },
    //详情
    seeDetails(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      sessionStorage.setItem("projectStudyTimeState", row.projectStudyTimeState);
      this.$router.push({
        path: "/web/classdetailsManage",
        query: {
          active: "first",
        },
      });
    },
    //班级列表
    jumpclassList(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      sessionStorage.setItem("projectStudyTimeState", row.projectStudyTimeState);
      this.$router.push({
        path: "/web/classdetailsManage",
        query: {
          active: "second",
        },
      });
    },
    //跳转学员列表
    jumpStudentList(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      sessionStorage.setItem("projectStudyTimeState", row.projectStudyTimeState);
      this.$router.push({
        path: "/web/classdetailsManage",
        query: {
          active: "three",
        },
      });
    },
    //开班申请
    keeprecord(projectId, projectSource, courseCount, row) {
      this.projectId = projectId;
      console.log(Number(courseCount));
      if (Number(courseCount) < 1) {
        this.$confirm("你的班级还未配置课程，请先为班级配课", "提示", {
          confirmButtonText: "班级配课",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.jumpclassList(row);
          })
          .catch(() => {
            return;
          });
      } else {
        if (row.paraIsPopQuestion) {
          this.$post("/biz/project/affirmCt", { projectId })
            .then((ret) => {
              if (ret.status == 0) {
                if (ret.data) {
                  this.retData = ret.data.popList;
                  this.popNum = ret.data.popNum;
                  if (this.retData.length) {
                    this.paraIsPopDialog = true;
                    return false;
                  }
                }

                this.getData(-1);
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          if (projectSource == "20") {
            this.dialogVisible = true;
            this.Periodnum = row.projectPc || 1;
            this.getAreaId();
          } else if (projectSource == "50") {
            //山西
            this.$confirm(
              "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
              "开班申请",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$post("/biz/project/affirmCt", { projectId })
                  .then((ret) => {
                    if (ret.status == 0) {
                      if (ret.data) {
                        this.retData = ret.data.list;
                        this.homeworkLimit = ret.data.homeworkLimit;
                        this.qualifiedLimit = ret.data.qualifiedLimit;
                        this.unitLimit = ret.data.unitLimit;
                        // this.popNum = ret.data.popNum;
                        if (this.retData.length) {
                          this.SxDialog = true;
                          return false;
                        }
                      }
                      this.getData();
                    }
                  })
                  .catch((err) => {
                    return;
                  });
              })
              .catch(() => {
                return;
              });
          } else {
            this.$confirm(
              "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
              "开班申请",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$post("/biz/project/affirmCt", { projectId })
                  .then((ret) => {
                    if (ret.status == 0) {
                      this.$message({
                        message: "开班申请成功",
                        type: "success",
                      });
                      this.getData(-1);
                    }
                  })
                  .catch((err) => {
                    return;
                  });
              })
              .catch(() => {
                return;
              });
          }
        }
      }
    },
    getAreaId() {
      this.$post("/sys/company/admin/comp")
        .then((ret) => {
          this.areaIdCompId = ret.data.areaId;
        })
        .catch((err) => {
          return;
        });
    },
    areaIdSure() {
      this.dialogVisible = false;
      this.$confirm(
        "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
        "开班申请",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$post("/biz/project/affirmCt", {
            projectId: this.projectId,
            areaId: this.areaIdCompId,
            projectPc: this.Periodnum,
          })
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: "开班申请成功",
                  type: "success",
                });
                this.getData(-1);
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {
          return;
        });
    },
    // 新增 编辑
    addTestPaper() {
      this.$router.push({
        path: "/web/addClassManage",
        query: {
          projectId: null,
          stu: "add",
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 60 + 0.675 * 16 ;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormnew"].resetFields();
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.getList(raw.projectId);
      this.selectedItem.name = raw.projectName;
      this.selectedItem.code = raw.projectCode;
      this.trainTypeId = raw.trainTypeId;
      this.areaId = raw.areaId;
    },
    getList(id) {
      this.$post("/run/project/archives", { projectId: id }).then((res) => {
        this.danganList = res.data;
      });
    },
    confirmDocsDialog() {
      // console.log(this.trainTypeId);
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            className: className,
            param: {
              projectId: this.selectedItem.id,
            },
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }

      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },


  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.name != "classListManage") return;
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.projectName = "";
            this.projectCode = "";
            this.ruleForm = {

            };
            this.params = {};
            this.getData();
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);
          this.projectName = "";
          this.projectCode = "";
          this.ruleForm = {

          };
          this.params = {};
          this.getData(-1);
        } else {
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less">
.el-table__body-wrapper {
  z-index: 2;
}
</style>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
</style>
<style lang="less" scope>
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
